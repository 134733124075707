'use client'
import { Anchor, Box, Center, Group, Stack, Text, Title } from '@mantine/core'
import { use, useEffect } from 'react'
import CrewHeroCard from '@/components/CrewHeroCard'
import PhoneLogin from './PhoneLogin'
import { auth } from '@/lib/auth'
import CrewHeroLogoDefault from '@/components/graphics/CrewHeroLogoDefault'

const AuthLoginPage = () => {
	const { actingAsCrewMember, user } = use(auth)

	useEffect(() => {
		if (actingAsCrewMember || user) {
			window.location.reload()
		}
	}, [actingAsCrewMember, user])

	if (actingAsCrewMember || user) {
		return null
	}

	return (
		<Stack bg='var(--body-shaded)' h='100vh' justify='center' align='center' gap='0'>
			<CrewHeroCard p='md' py='lg' w='20rem'>
				<Center w='100%' h='6rem'>
					<Box maw='12rem'>
						<CrewHeroLogoDefault />
					</Box>
				</Center>
				<Stack gap='md' align='center'>
					<Title order={2}>Sign in</Title>
					<PhoneLogin />
				</Stack>
				<Group justify='space-between' pt='xl'>
					<Anchor href='/login/other-login'>
						<Group gap='xxxs'>
							<Text size='sm' c='dimmed' ta='left'>
								Use email instead
							</Text>
						</Group>
					</Anchor>
					<Group gap='sm'>
						<Anchor href='https://crewhero.app/help' target='_blank'>
							<Text size='sm' c='dimmed'>
								Help
							</Text>
						</Anchor>
						<Anchor href='https://crewhero.app/legal/terms-of-service' target='_blank'>
							<Text size='sm' c='dimmed'>
								Terms
							</Text>
						</Anchor>
						<Anchor href='https://crewhero.app/legal/privacy-policy' target='_blank'>
							<Text size='sm' c='dimmed'>
								Privacy
							</Text>
						</Anchor>
					</Group>
				</Group>
			</CrewHeroCard>
		</Stack>
	)
}

export default AuthLoginPage

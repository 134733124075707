'use client'
import { Combobox, Group, Stack, useCombobox } from '@mantine/core'
import { useState } from 'react'
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'
import { ScrollArea, Button, Input, Text } from '@mantine/core'
import type { CountryIso2 } from 'react-international-phone'
import 'react-international-phone/style.css'

type PhoneLoginInputProps = {
	value?: string
	onChange?: (value: string) => void
	onFocus?: () => void
	error?: string
	submitting?: boolean
	name?: string
	onBlur?: React.FocusEventHandler<HTMLInputElement>
	autocomplete?: boolean
}

const PhoneLoginInput: React.FC<PhoneLoginInputProps> = ({
	onChange,
	autocomplete = false,
	...props
}) => {
	const error = props.error
	const [search, setSearch] = useState<string>('')
	const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
		defaultCountry: 'us',
		value: props.value,
		countries: defaultCountries,
		disableDialCodeAndPrefix: true,
		onChange: (data) => {
			onChange?.(data.phone)
		},
	})
	const combobox = useCombobox({
		onDropdownClose: () => {
			combobox.resetSelectedOption()
			combobox.focusTarget()
			setSearch('')
		},

		onDropdownOpen: () => {
			combobox.focusSearchInput()
		},
	})

	const options = defaultCountries
		.filter((country) => {
			const parsedCountry = parseCountry(country)
			const searchLower = search.toLowerCase().trim()

			const dialCodeMatch =
				parsedCountry.dialCode.toString() === searchLower ||
				`+${parsedCountry.dialCode}` === searchLower

			const nameMatch = parsedCountry.name.toLowerCase().includes(searchLower)

			return nameMatch || dialCodeMatch
		})
		.map((c) => {
			const country = parseCountry(c)
			return (
				<Combobox.Option key={country.iso2} value={country.iso2}>
					<Group>
						<FlagImage iso2={country.iso2} style={{ display: 'flex' }} />+{country.dialCode}{' '}
						{country.name}
					</Group>
				</Combobox.Option>
			)
		})
	return (
		<Combobox
			store={combobox}
			onOptionSubmit={(val) => {
				setCountry(val as CountryIso2)
				combobox.closeDropdown()
			}}
			size='md'
		>
			<Combobox.Dropdown>
				<Combobox.Search
					value={search}
					onChange={(event) => setSearch(event.currentTarget.value)}
					placeholder='Search by country dial code'
				/>
				<Combobox.Options>
					<ScrollArea.Autosize type='scroll' mah={200}>
						{options.length > 0 ? options : <Combobox.Empty>Nothing found</Combobox.Empty>}
					</ScrollArea.Autosize>
				</Combobox.Options>
			</Combobox.Dropdown>
			<Stack gap='xxxs'>
				<Input.Label size='lg'>Phone number</Input.Label>

				<Combobox.Target>
					<Input
						aria-label='Phone number'
						aria-required='true'
						aria-describedby='phone-number-input-description'
						aria-invalid={error ? 'true' : 'false'}
						aria-errormessage={error ? 'phone-number-input-error' : undefined}
						autoComplete={autocomplete ? 'tel' : 'off'}
						name='phone'
						type='phone' // Switch to text
						placeholder='(555) 123-4567'
						id='tel-national'
						inputMode='numeric'
						leftSectionPointerEvents='all'
						leftSectionWidth={56} // Adjust as needed based on longest dial code
						leftSectionProps={{
							style: {
								alignItems: 'center',
								justifyContent: 'center',
								paddingLeft: '0.25rem',
							},
						}}
						leftSection={
							<Button
								size='lg'
								variant='default'
								p='0'
								bd='0'
								fullWidth
								onClick={() => combobox.toggleDropdown()}
								style={{
									whiteSpace: 'nowrap',
								}}
							>
								<Text size='lg' ta='center' fw={500}>
									+{country?.dialCode}
								</Text>
							</Button>
						}
						value={inputValue}
						onChange={handlePhoneValueChange}
						autoFocus
						enterKeyHint='go'
						ref={inputRef}
						size='xl'
					/>
				</Combobox.Target>
			</Stack>
			<Button
				size='lg'
				type='submit'
				disabled={props.submitting}
				loading={props.submitting}
				loaderProps={{ type: 'bars', size: 'sm' }}
			>
				Next
			</Button>
		</Combobox>
	)
}

export default PhoneLoginInput

'use client'
import { logger } from '@/utils/logger'
import { InputError, Text, Anchor, Stack } from '@mantine/core'
import { type ReactNode, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { AuthError } from '@supabase/supabase-js'
import {
	checkForCrewMember,
	checkForUser,
	createSMSOTP,
	createUserFromPhone,
} from '@/authActions/loginActions'
import { useForm } from '@mantine/form'
import PhoneLoginInput from './PhoneLoginInput'

const env = process.env.NEXT_PUBLIC_ENV
const initialValues =
	env === 'local'
		? { phone: process.env.NEXT_PUBLIC_LOCAL_PHONE || '', email: '', password: '' }
		: { phone: '', email: '', password: '' }

const PhoneLogin = () => {
	const router = useRouter()
	const searchParams = useSearchParams()
	const nextPath = searchParams?.get('next') || '/auth/lander'
	const [error, setError] = useState<string | ReactNode>()

	const signinForm = useForm<{ email: string; phone: string; password: string }>({
		initialValues: initialValues,
	})

	const handleOTPSignin = async (values: typeof signinForm.values) => {
		try {
			const phoneValue = values.phone
			setError(undefined)

			if (phoneValue) {
				const formattedPhone = phoneValue.trim()
				const cm = await checkForCrewMember({ phone: formattedPhone })

				if (!cm?.id) {
					throw new AuthError('Crew member not found. Contact your manager to get setup.')
				}

				const userExists = await checkForUser({ phone: formattedPhone })

				if (!userExists) {
					const { ok, error } = await createUserFromPhone(cm.id)
					if (!ok || error) {
						logger.error('[Phone Login] createUserFromPhone error not okay or error', error)
						throw new Error(error)
					}
				}

				const result = await createSMSOTP(formattedPhone)

				if (result.ok) {
					router.push(
						`/auth/verify-phone-otp?phone=${encodeURIComponent(formattedPhone)}&next=${encodeURIComponent(nextPath)}`,
					)
				} else if (result.code === 'otp_signup_not_allowed') {
					setError(
						<Text size='sm' c='red'>
							User for this cell not setup. Please contact{' '}
							<Anchor href='mailto:support@crewhero.app'>support@crewhero.app</Anchor>
						</Text>,
					)
				} else if (result.message) {
					setError(result.message)
				} else {
					throw new Error('An unknown error occurred')
				}
			} else {
				throw new Error('Phone number is required')
			}
		} catch (error) {
			if (error instanceof AuthError) {
				setError(error.message)
			} else if (error instanceof Error) {
				setError(error.message)
			} else {
				logger.error('handleOTPSignin error', error)
				setError('An unknown error occurred')
			}
		}
	}

	return (
		<form
			aria-label='Phone number input'
			aria-required='true'
			aria-describedby='phone-number-input-description'
			aria-invalid={error ? 'true' : 'false'}
			aria-errormessage={error ? 'phone-number-input-error' : undefined}
			autoComplete='on'
			onSubmit={signinForm.onSubmit(handleOTPSignin)}
		>
			<Stack gap='sm'>
				<PhoneLoginInput
					{...signinForm.getInputProps('phone')}
					submitting={signinForm.submitting}
				/>
				{error && <InputError size='md'>{error}</InputError>}
			</Stack>
		</form>
	)
}

export default PhoneLogin
